import React, { Component } from "react";
import "./compatibledevices.css";
import Footer from "./footer.js";
import { Helmet } from "react-helmet";

class Howtoconnect extends Component {
  constructor() {
    super();
    this.state = {
      tutorial1: true,
      tutorial2: true,
    };
    this.switchTutorial1 = this.switchTutorial1.bind(this);
    this.switchTutorial2 = this.switchTutorial2.bind(this);
  }

  switchTutorial1(icon) {
    if (icon === 1) {
      this.setState({ tutorial1: true });
    } else if (icon === 2) {
      this.setState({ tutorial1: false });
    }
  }

  switchTutorial2(icon) {
    if (icon === 1) {
      this.setState({ tutorial2: true });
    } else if (icon === 2) {
      this.setState({ tutorial2: false });
    }
  }

  tutorial1() {
    if (this.state.tutorial1) {
      return (
        <section className="wired">
          <Helmet>
            <title>JETSWEAT | Compatible Devices</title>
            <meta
              name="description"
              content="Bring the boutique studio experience to your home or anywhere you go. Stream HIIT, Yoga, Dance, and many more exclusive classes  on your phone, tablet, computer, or TV 24/7 on-demand."
            ></meta>
          </Helmet>
          <div className="pure-g ">
            <div className="pure-u-md-10-24">
              <div className="pure-u-2-3 wired-left-top">
                <img
                  src={require("../images/compatibledevices/4.png")}
                  alt="Jetsweat app running on a TV"
                  className="pure-img"
                />
              </div>
              <div className="pure-u-1-3 wired-left-top">
                <img
                  src={require("../images/compatibledevices/5.png")}
                  alt="Jetsweat app running on a TV"
                  className="pure-img wired-hdmi-cable"
                />
              </div>
              <div className="pure-u-l wired-left-center">
                <img
                  src={require("../images/compatibledevices/3.png")}
                  alt="group of people working out in a fitness salon"
                  className="pure-img"
                />
              </div>
            </div>
            <div className="pure-u-md-14-24">
              <div className="right-content">
                <p className="wired-right-text">
                  The easiest way to connect your computer to your TV is by
                  plugging an HDMI cable (buy one
                  <a
                    className="hdmi-link"
                    href="https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=HDMI+cord&rh=i%3Aaps%2Ck%3AHDMI+cord"
                  >
                    HERE
                  </a>
                  for around $7) .
                  <br />
                  <br />
                  Virtually all laptops and TVs have an HDMI port but if you
                  don’t have one you can buy an HDMI adapter
                  <a
                    className="hdmi-link"
                    href="https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=HDMI+adapter&rh=i%3Aaps%2Ck%3AHDMI+adapter"
                  >
                    HERE
                  </a>
                  (around $9) which plugs into your computer’s USB port.
                  <br />
                  <br />
                </p>

                <ol>
                  <li>Locate the HDMI ports.</li>
                  <li>Attach one end of the HDMI cable to your computer.</li>
                  <li>
                    Plug the other end of the HDMI cable into your TV.
                  </li>
                  <li>
                    Select the correct HDMI input using your TV remote (usually
                    by pressing the AV button, HDMI 1 or 2 depending on which
                    you plug into).
                  </li>
                  <li>Play JETSWEAT!</li>
                  <li>
                    If your laptop does not automatically output its screen to
                    the TV, go to Control Panel > Display > Adjust Resolution
                    and select the TV in the Display drop down box.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return (
      <section className="wireless">
        <div className="pure-g">
          <div className="pure-u-md-10-24">
            <div className="wireless-right">
              <img
                src={require("../images/compatibledevices/wifi.png")}
                alt="Jetsweat app running on a laptop"
                className="pure-img"
              />
            </div>
          </div>
          <div className="pure-u-md-14-24">
            <div className="right-content">
              <p className="wired-right-text">
                If you can't or don't want to have a wire between your laptop
                and TV, another option is to go wireless.
                <br />
                <br />
              </p>
              <ul>
                <li>
                  There are various options here depending on your laptop's
                  capabilities since a few laptops have a built-in wireless
                  system called WiDi (Intel Wireless Display) which works with a
                  compatible receiver such as Netgear's Push2TV.
                </li>
                <li>
                  A different way is to use a dedicated media streamer such as,
                  Google's Chromecast, Roku's Streaming Stick, or even Apple's
                  TV. You can view further instructions in this{" "}
                  <a
                    className="hdmi-link"
                    href=" https://www.techadvisor.co.uk/how-to/laptop/connect-laptop-tv-3280787/"
                  >
                    ARTICLE{" "}
                  </a>
                  .
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }

  tutorial2() {
    if (this.state.tutorial2) {
      return (
        <section className="chromecast">
          <div className="pure-g">
            <div className="pure-u-md-10-24 stream-image">
              <img
                src={require("../images/compatibledevices/Chromecast-png.png")}
                alt="Jetsweat app running on a TV"
                className="pure-img"
              />
            </div>
            <div className="pure-u-md-14-24">
              <div className="right-content">
                <p className="chrome-text">- Plug into power & TV:</p>
                <p>
                  Plug cable into Chromecast
                  <br />
                  Plug Chromecast into TV
                  <br />
                  Plug power adapter into wall socket
                </p>
                <p className="chrome-text">- Switch TV input</p>
                <p>
                  Switch TV input/source to the HDMI port your Chromecast is
                  plugged into
                </p>
                <p className="chrome-text">- Set it up:</p>
                <p>
                  From your phone, tablet or laptop, visit: chromecast.com/setup
                </p>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return (
      <section className="airplay">
        <div className="pure-g">
          <div className="pure-u-md-10-24 stream-image">
            <img
              src={require("../images/compatibledevices/111.png")}
              alt="Jetsweat app running on a TV"
              className="pure-img"
            />
          </div>
          <div className="pure-u-md-14-24">
            <div className="right-content">
              <p className="chrome-text">Install</p>
              <p>Install the Apple TV Remote app from iTunes</p>
              <p className="chrome-text">Mirroring</p>
              <p>
                From your iPhone you can swipe up and use the "AirPlay
                Mirroring" option in the menu Set it up
              </p>
              <p className="chrome-text">Set it up</p>
              <p>
                Enter the code on your iPhone to your Apple TV to pair the
                iPhone (Settings > General > Remotes)Select your iPhone on the
                Apple TV screen
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  render() {
    return (
      <div>
        <section className="how-it-works">
          <div className="pure-g">
            <div>
              <div className="pure-u-md-1-2 pure-u-sm-1 box-1-1" style={{paddingTop: 45 }}>
                <h1
                  className="access-text"
                  /* data-aos="fade-in"
                data-aos-easing="ease-in-sine"
                data-aos-duration="2000" */
                >
                  Stream workouts from your favorite device
                </h1>
                <p>
                Whether at home or on-the-go, you can access JETSWEAT from the web-based platform, 
                iOS app, Roku app, or cast to a larger TV screen to immerse yourself in the full 
                studio experience
                </p>
                <div>
                  <div className="pure-u-1-5 box-1-2">
                    <img
                      className="laptop-icon pure-img"
                      /* data-aos="fade-up"
                    data-aos-duration="600" */
                      src={require("../images/compatibledevices/laptop-icon-@2x.png")}
                      alt="Laptop Icon"
                    />
                    <p>Web</p>
                  </div>
                  <div className="pure-u-1-5 box-1-2">
                    <img
                      className="mobile-icon pure-img"
                      /* data-aos="fade-up"
                    data-aos-duration="700" */
                      src={require("../images/compatibledevices/phone-logo@2x.png")}
                      alt="Mobile Icon"
                    />
                    <p>Mobile</p>
                  </div>
                  <div className="pure-u-1-5 box-1-2">
                    <img
                      className="chromecast-icon pure-img"
                      /* data-aos="fade-up"
                    data-aos-duration="800" */
                      src={require("../images/compatibledevices/chromcast-logo@2x.png")}
                      alt="Chromecast Icon"
                    />
                    <p className="chromecast-text-size-compatible-devices">
                      Chromecast
                    </p>
                  </div>
                  <div className="pure-u-1-5 box-1-2">
                    <img
                      className="airplay-icon pure-img"
                      /* data-aos="fade-up"
                    data-aos-duration="900" */
                      src={require("../images/compatibledevices/airplay-logo@2x.png")}
                      alt="Airplay Icon"
                    />
                    <p>Airplay</p>
                  </div>
                  <div className="pure-u-1-5 box-1-2">
                    <img
                      className="hdmi-icon pure-img"
                      /* data-aos="fade-up"
                    data-aos-duration="1000" */
                      src={require("../images/compatibledevices/HDMI.png")}
                      alt="hdmi icon"
                    />
                    <p>HDMI</p>
                  </div>
                </div>
              </div>
              <div className="pure-u-md-1-2 pure-u-sm-1 box-1-1">
                <img
                  className="hdmi-icon pure-img"
                  /* data-aos="fade-in" */
                  src={require("../images/compatibledevices/howitwork.png")}
                  alt="Group of woman working out in a bright room"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="access">
          <div className="pure-g">
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1" id="A-box-2-1">
              <img
                src={require("../images/compatibledevices/access.png")}
                alt="Jetsweat app running on a laptop"
                className="pure-img"
                /* data-aos="fade-up"
              data-aos-offset="300" */
              />
            </div>
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1">
              <div>
                <h2
                  className="access-text"
                  /* data-aos="fade-up"
                data-aos-offset="300" */
                >
                  Access On Demand boutique studios from anywhere
                </h2>
                <p>
                  You can stream to a TV using a HDMI cord <br /> Using the
                  instructions below
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="tabs">
          <div className="pure-u-1-2">
            <div
              className={
                this.state.tutorial1
                  ? "pure-button button-clicked"
                  : "pure-button button"
              }
              href="#"
              onClick={() => {
                this.switchTutorial1(1);
              }}
            >
              <img
                src={require("../images/compatibledevices/HDMI.png")}
                alt="HDMI Cord"
                className="pure-img"
              />
              HDMI Cord
            </div>
          </div>
          <div className="pure-u-1-2">
            <div
              className={
                this.state.tutorial1
                  ? "pure-button button"
                  : "pure-button button-clicked"
              }
              href="#"
              onClick={() => {
                this.switchTutorial1(2);
              }}
            >
              <img
                src={require("../images/compatibledevices/Wifi@2x.png")}
                alt="Wifi"
                className="pure-img"
              />
              Wireless
            </div>
          </div>
        </section>

        {this.tutorial1()}
        <section className="android-section">
          <div className="pure-g android-section-div">
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1" id="A-box-2-1">
              <img
                src={require("../images/compatibledevices/dashboardandroid.png")}
                alt="Jetsweat app running on a mobile"
                className="Mobile phone image with the Jetsweat app running"
                style={{ width: "30vh", height: "auto" }}
                /* data-aos="fade-up"
              data-aos-offset="700" */
              />
            </div>
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1">
              <div className="access-div ">
                <p
                  className="access-text"
                  /* data-aos="fade-up"
                data-aos-offset="700" */
                >
                  Download the Android app
                </p>
                <p
                  className="access-text"
                  style={{ fontSize: "24px", textAlign: "center" }}
                >
                  {" "}
                  COMING SOON
                </p>
                <a href="#">
                  <img
                    src={require("../images/compatibledevices/android-store-icon.png")}
                    alt="android store icon"

                    /* data-aos="fade-up"
              data-aos-offset="700" */
                  />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="roku-section">
          <div className="pure-g">
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1" id="A-box-2-1">
              <img
                src={require("../images/compatibledevices/roku-img.png")}
                alt="image of Jetsweat running on the tv Roku app"
                className="roku-tv-img"
                /* data-aos="fade-up"
              data-aos-offset="300" */
              />
            </div>
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1">
              <div className="roku-text-box">
                <p
                  className="h1 access-text "
                  /* data-aos="fade-up"
                data-aos-offset="300" */
                >
                  Get the Roku app
                </p>
                <p className="roku-p">
                  JETSWEAT can be streamed anywhere. All JETSWEAT members have
                  access to Roku.
                </p>
                <a
                  href="https://channelstore.roku.com/details/16f509e65f017eaed463030b99a47b50/jetsweat"
                  target="_blank"
                >
                  {" "}
                  <img
                    src={require("../images/compatibledevices/231-2319913_roku-channel-black-and-white-hd-png-download.webp")}
                    alt="android store icon"
                    className=""
                    /* data-aos="fade-up"
              data-aos-offset="700" */
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="download">
          <div className="pure-g">
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1" id="A-box-2-1">
              <img
                src={require("../images/compatibledevices/App.png")}
                alt="Jetsweat app running on a Tablet and Mobile"
                className="pure-img"
                /* data-aos="fade-up"
              data-aos-offset="700" */
              />
            </div>
            <div className="pure-u-md-1-2 pure-u-sm-1 box-2-1">
              <div className="access-div">
                <p
                  className="h1 access-text"
                  /* data-aos="fade-up"
                data-aos-offset="700" */
                >
                  Download the iOS app
                </p>
                <p>
                  You can stream from your app to a TV using Chromecast or
                  Airplay
                </p>
                <a
                  href="https://apps.apple.com/us/app/jetsweat/id1262223853 "
                  target="_blank"
                >
                  {" "}
                  <img
                    src={require("../images/compatibledevices/app-store-icon.png")}
                    alt="android store icon"
                    className=""
                    /* data-aos="fade-up"
              data-aos-offset="700" */
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="tabs">
          <div className="pure-u-1-2">
            <div
              className={
                this.state.tutorial2
                  ? "pure-button button-clicked"
                  : "pure-button button"
              }
              href="#"
              onClick={() => {
                this.switchTutorial2(1);
              }}
            >
              <img
                src={require("../images/compatibledevices/chromcast-logo@2x.png")}
                alt="Chromecast"
                className="pure-img"
              />
              Chromecast
            </div>
          </div>
          <div className="pure-u-1-2">
            <div
              className={
                this.state.tutorial2
                  ? "pure-button button"
                  : "pure-button button-clicked"
              }
              href="#"
              onClick={() => {
                this.switchTutorial2(2);
              }}
            >
              <img
                src={require("../images/compatibledevices/airplay-logo@2x.png")}
                alt="Airplay"
                className="pure-img"
              />
              Airplay
            </div>
          </div>
        </section>

        {this.tutorial2()}

        <Footer />
      </div>
    );
  }
}

export default Howtoconnect;
