import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import Footer from "./footer.js";
import Shakehand from "../assets/Shake_hand.png";
import increaseRetentionImg from "../assets/IncreaseRetention.png";
import improveProductivityImg from "../assets/ImproveProductivity.png";
import yogaImg from "../assets/Health_yoga.png";
import businessSavingsImg from "../assets/BusinessSavings.svg";
import firstPic from "../assets/health_care.png";
import closeButton from "../assets/Vector.png";
import grayEclipseImg from "../assets/GrayEclipse.svg";
import fifteen from "../assets/health_15percent.png";
import thirtyfour from "../assets/health_34percent.png";
import seventysix from "../assets/health_76percent.png";


import "./Healthplan.css"

const Healthcare = () => {

  const [text, setText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    function updateText() {
      if (window.innerWidth < 625) {
        setText(`JETSWEAT
        Workplace Wellness`);
      } else {
        setText('For Health Plans ');
      }
    }

    updateText();

    window.addEventListener('resize', updateText);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateText);
    };
  }, []);

  // Hubspot react forms
  useEffect(()=>{
    console.log('react hubspot')
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: '23498037', // Replace with your HubSpot portal ID
          formId: 'b093e0bb-7f43-46dc-84da-228f8e3cf6b7', // Replace with your form ID
          target: '#health-FormModal' 
        });
      }
    });

    return () => {
      // Clean up: Remove the script from the document on component unmount
      document.body.removeChild(script);
    };
  }, [openModal])

  // Using up and down key to scroll
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (openModal) {
        switch (e.key) {
          case 'ArrowUp':
            handleScroll(-1);
            break;
          case 'ArrowDown':
            handleScroll(1);
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openModal]);

  const handleScroll = (direction) => {
    const modalContent = document.getElementsByClassName('modal-main');

    if (modalContent) {
      modalContent[0].scrollTop += direction * 20; // Adjust the scrolling speed as needed
      setScrollPosition(modalContent.scrollTop);
    }
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    document.body.classList.add('workplace-modal-open');
  }
  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    document.body.classList.remove('workplace-modal-open');
  }

  return (
    <>
      <div className="image-container">
        <img className="first-img" src={firstPic} alt="Mindful Hands" />
        <div className="overlay-container">
          <div className="overlay-main">
            <h1 className="main-title">{text}</h1>

            <p className="first-paragraph">
              JETSWEAT Wellness simplifies the initiation and continuity of health journeys for your members and their families.
            </p>

            <button className="first-button" onClick={handleOpenModal}>Get in Touch</button>
          </div>
        </div>
      </div>

      <div className='two-columns'>
        {/* column left */}
        <div className='who-we-serve'>
          <h2 className="who-we-serve-header">Who We Serve</h2>
          <p className="who-we-serve-body">
            JETSWEAT Wellness fosters both physical and mental well-being for health plans
            of any scale or nature that emphasize the importance of health and overall welfare.
          </p>
        </div>

        {/* column right */}
        <div className="hr-column">
          <img src={Shakehand} alt="Shakehand" className="hr-image" />
          <div className="hr-text">
            <h2 className="hr-header">Health Plans</h2>
            <p className="hr-body">
              Wellness programs supported by health plans encompass a
              holistic approach to health, focusing on physical well-being, mental, and emotional health
            </p>
          </div>
        </div>
      </div>

      {/* Business Needs Section */}
      <div className="business-needs">
        <div>
          <h2 className="business-header">We Meet Provider Needs</h2>
          <p2 className="business-body">
            Our top-of-the-line wellness resources cater to individual needs,
            lifestyles, and busy schedules, ensuring a perfect fit for every member of your network.
          </p2>
        </div>

        <div className="three-columns">
          <div className="business-column">
            <div className="business-icon-and-header">
              <img src={businessSavingsImg} alt="Down Arrow Icon" />
              <div className="business-column-header">
                <h2>$3.27 saved / $1 spent</h2>
              </div>
            </div>
            <p className="business-column-body">
              Reduce total cost of care. $3.27 is saved on medical costs per $1 spent
            </p>
          </div>
          <div className="business-column">
            <div className="business-icon-and-header">
              <img src={increaseRetentionImg} alt="Increase Retention Icon" />
              <div className="business-column-header">
                <h2>Increase Savings</h2>
              </div>
            </div>
            <p className="business-column-body">
              Less claims mean savings back to policyholders through discounted premiums or program rewards
            </p>
          </div>
          <div className="business-column">
            <div className="business-icon-and-header">
              <img src={improveProductivityImg} alt="Check on Board Icon" />
              <div className="business-column-header">
                <h2>Better Clinical Outcomes</h2>
              </div>
            </div>
            <p className="business-column-body">
              Studies show that health plans with a wellness benefit can improve the activity of some policyholders
            </p>
          </div>
        </div>
      </div>

      {/* Empower Health Section */}
      <div className="empower-background">
        <h2 className="empower-header">
          Empower a Healthy Member Community through Movement
        </h2>
        <p className="empower-main-p">
          Wellness initiatives have the potential to improve the mortality outcomes of those who are insured.
        </p>
        <div className="three-columns-business">
          <div className="business-column">
            <div className="overlapping-percentage">
              <img
                src={grayEclipseImg}
                alt="15 percent"
                className="percent-first-img"
              />
              <img
                src={fifteen}
                alt="15 percent"
                className="percent-second-img"
              />
            </div>

            <div className="empower-text">
              <p className="empower-paragraph">
                Wellness program lowered health plans lapse rates by 15%              </p>
            </div>
          </div>

          <div className="business-column">
            <div className="overlapping-percentage">
              <img
                src={grayEclipseImg}
                alt="76 percent"
                className="percent-first-img"
              />
              <img
                src={seventysix}
                alt="76 percent"
                className="percent-second-img"
              />
            </div>
            <p className="empower-paragraph">
              Employees participating in wellness programs lost 10 pounds on average           </p>
          </div>

          <div className="business-column">
            <div className="overlapping-percentage">
              <img
                src={grayEclipseImg}
                alt="34 percent"
                className="percent-first-img"
              />
              <img
                src={thirtyfour}
                alt="34 percent"
                className="percent-second-img"
              />
            </div>
            <p className="empower-paragraph">
              Participants in wellness programs engaged in 34% more activity
            </p>
          </div>
        </div>
      </div>
      {/* Empower Health Section Ends */}


      {/* Ignite Section */}
      <div className="ignite-container">
        <div className="ignite-text">
          <h2 className="ignite-header">
          Igniting Business Success Through a Healthy Member Community          </h2>
          <p2 className="ignite-paragraph">
            True transformation happens when individuals experience their
            optimal selves.
          </p2>

          <div>
            <button className="second-button" onClick={handleOpenModal}>Get in Touch</button>
          </div>
        </div>

        <img src={yogaImg} alt="Hand holding plants" className="ignite-image" />
      </div>
      {/* Ignite Session Ends */}

      <Modal style={{ overlay: { backgroundColor: 'rgba(0,0,0,0.75' } }}
        isOpen={openModal}
        className={`workplace-modal ${openModal ? 'open' : ''}`}
        shouldCloseOnOverlayClick={true}
      >

        <div className='modal-main'>
          <button 
            className="close-modal-button" 
            onClick={handleCloseModal}
          ><img src={closeButton}/> </button>
          <div className='health-modal-header'>
            <h3>Get In Contact</h3>
            <p>Ask about our flexible pricing options!</p>
          </div>
          <div id="health-FormModal"  className='health-form'></div>
        </div>

      </Modal>

      <Footer />
    </>
  );
}

export default Healthcare;
