import React, { Component } from "react";
import NavLink from "./components/navlink.js";
import Link from "screens/home/components/link";
import { Link as RouterLink } from "react-router-dom";
import Logo from "./assets/logo@3x.png";
import axios from "axios";
import MappedStudioList from "components/MappedStudioList";

import "./navbar.css";

import {
  STUDIOS,
  HOW_IT_WORKS,
  HEALTH_CARE,
  LIVE_SCHEDULE,
  LOG_IN,
  LOG_OUT,
  HOME,
  WORKPLACE_WELLNESS,
  EDUCATION,
  WHITE_LABEL,
} from "constants/routes";

export default class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      isMenuVisibe: false,
      studioInfoList: [],
      studiosHidden: true,
      whoWeServeHidden: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    const { data } = await axios.get("/api/basic/webStudiosInfo");
    this.setState({ ...this.state, studioInfoList: [...data.content] });
  }

  onClickToggleHamburger() {
    this.setState({
      isMenuVisibe: !this.state.isMenuVisibe,
    });
  }
  handleClick(e) {
    e.stopPropagation();
    if (this.state.studiosHidden) {
      this.setState({ studiosHidden: false });
    } else {
      this.setState({ studiosHidden: true });
    }
  }

  handleWhoWeServeClick(menu, e) {
    e.stopPropagation();
    this.setState(prevState => ({
      [menu]: !prevState[menu]
    }));
  }
  

  getMenu() {
    const renderArrow =
      this.state.studiosHidden === true ? "" : <span>&#9662;</span>;

    const renderWhoWeServeArrow = 
      this.state.whoWeServeHidden ? "" : <span>&#9662;</span>;
    let visibility = "hide";
    if (!this.props.hidden) {
      visibility = "show";
    }

    if (!this.state.isMenuVisibe) return null;
    if (this.props.whiteLabelDomain) {
      return (
        <div className="d-flex flex-column js-navbar-menu-mb">
          <a href="/signup">START YOUR FREE TRIAL</a>
          <a href={this.state.isLoggedIn ? "/logout" : "/login"}>
            {this.state.isLoggedIn ? "LOGOUT" : "LOGIN"}
          </a>
        </div>
      );
    } else {
      return (
        <div className="d-flex flex-column js-navbar-menu-mb">
          {this.state.isLoggedIn?(
            <div className="menu-studio-options">
            <li style={{listStyle:'none'}}>
              DASHBOARD
            </li>
          </div>
          ):(null)}
          
          {!this.props.whiteLabelDomain && (
            <li onClick={this.handleClick} style={{ listStyle: "none" }}>
              WELLNESS PARTNERS {renderArrow}
              {/* <a href="/studios">STUDIOS</a> */}
            </li>
          )}
          {this.state.studiosHidden ? null : (
            <div className="menu-studio-options">
              <ul>
                <RouterLink to="/studios">Check out All Studios</RouterLink>
                <ul>
                  <MappedStudioList
                    studioInfoList={this.state.studioInfoList}
                  />
                </ul>
              </ul>
            </div>
          )}
          <div className="menu-studio-options">
            <li onClick={(e) => this.handleWhoWeServeClick('whoWeServeHidden', e)} style={{ listStyle: "none" }}>
              WHO WE SERVE {renderWhoWeServeArrow}
            </li>
            {!this.state.whoWeServeHidden && (
              <ul>
                <li>
                  <a href="/how-it-works">Individuals</a>
                </li>
                <li>
                  <a href="/whoweserve/employers">Workplace Wellness</a>
                </li>
                <li>
                  <a href="/whoweserve/healthplanss">Health Plans</a>
                </li>
                <li>
                  <a href="/whoweserve/schools">Education</a>
                </li>
              </ul>
            )}
          </div>
          <a href="/signup">START YOUR FREE TRIAL</a>
          <a href="/liveschedule">LIVE SCHEDULE</a>
          <a href="https://jetsweat.io">WHITE LABEL</a>
          <a href={this.state.isLoggedIn ? "/logout" : "/login"}>
            {this.state.isLoggedIn ? "LOGOUT" : "LOGIN"}
          </a>

        </div>
      );
    }
  }

  render() {
    return (
      <>
        <div className="js-navbar-container d-flex align-items-center justify-content-between">
          <a href={HOME}>
            <img alt="logo" src={Logo} className="js-navbar-logo" />
          </a>
          <div className="d-flex js-navbar-links">
            {this.props.isLoggedIn && (
              <NavLink linkHref={"/newplatform/dashboard"} label="Dashboard" />
            )}
            <NavLink
              linkHref={STUDIOS}
              studioInfoList={this.state.studioInfoList}
              label="Wellness Partners"
            />
            <NavLink
                linkHref="#"  // Placeholder since it's a dropdown
                // change to who we serve
                label="Who We Serve" 
                subMenuItems={[
                    {label: "Individuals", href: HOW_IT_WORKS},
                    {label: "Health Plans", href: HEALTH_CARE},
                    {label: "Workplace Wellness", href: WORKPLACE_WELLNESS},
                    {label: "Education", href: EDUCATION}
                ]}
            />
            <div className="live-schedule">
              <NavLink linkHref={LIVE_SCHEDULE} label="Live Schedule" />
            </div>
          </div>
          {this.props.isLoggedIn ? 
            <div className="d-flex login-btn">
              <NavLink linkHref={LOG_OUT} label="Log Out" />
            </div>
          :
            <div className="d-flex login-btn">
              <NavLink linkHref={WHITE_LABEL} label="White Label" />
              <div className="vr" style={{borderLeft: "3px solid #ECECEC", height: "40px"}}></div>
              <NavLink linkHref={LOG_IN} label="Log In" />
            </div>
          }
          <Link
            className="js-home-hero-cta-button-nav-link"
            label="Start your free trial"
            href="/signup"
          />
        </div>
        <div className="js-navbar-container-mb js-navbar-mb">
          <div className="d-flex justify-content-between align-items-center">
            <a href={HOME}>
              <img alt="logo" src={Logo} className="js-navbar-logo" />
            </a>
            <div className="d-flex align-items-center">
              <Link
                className="js-navbar-signup-btn-mb js-home-hero-cta-button-link"
                label="Start your free trial"
                color="light-green"
                href="/signup"
              />
              <svg
                onClick={this.onClickToggleHamburger.bind(this)}
                width="30"
                height="30"
                className="js-navbar-hamburger"
              >
                <path d="M0,5 30,5" stroke="#2C2C2C" strokeWidth="5" />
                <path d="M0,14 30,14" stroke="#2C2C2C" strokeWidth="5" />
                <path d="M0,23 30,23" stroke="#2C2C2C" strokeWidth="5" />
              </svg>
            </div>
          </div>
          {this.getMenu()}
        </div>
      </>
    );
  }
}
