import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import Footer from "./footer.js";
import hrImage from "../assets/workplace_wellness_hr.png";
import increaseRetentionImg from "../assets/IncreaseRetention.png";
import improveProductivityImg from "../assets/ImproveProductivity.png";
import businessSavingsImg from "../assets/BusinessSavings.svg";
import igniteImg from "../assets/ignitingBusiness.png";
import grayEclipseImg from "../assets/GrayEclipse.svg";
import EightyThree from "../assets/eightythree.png";
import SeventyFive from "../assets/seventyfive.png";
import EightFive from "../assets/eightyfive.png";
import firstPic from "../assets/workplace_wellness_hero.png";
import closeButton from "../assets/Vector.png";

import "./WorkplaceWellness.css";

const WorkplaceWellness = () => {

  const [text, setText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    function updateText() {
      if (window.innerWidth < 625) {
        setText(`JETSWEAT
        Workplace Wellness`);
      } else {
        setText('Empower your employees with a complete platform that drives health outcomes and lowers costs');
      }
    }

    updateText();

    window.addEventListener('resize', updateText);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateText);
    };
  }, []);

  // Hubspot react forms
  useEffect(()=>{
    console.log('react hubspot')
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '23498037', // Replace with your HubSpot portal ID
          formId: '9649ccc1-a6e6-4a67-80bd-4cce292e497c', // Replace with your form ID
          target: '#hubspotFormModal' 
        });
      }
    });

    return () => {
      // Clean up: Remove the script from the document on component unmount
      document.body.removeChild(script);
    };
  }, [openModal])

  // Using up and down key to scroll
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (openModal) {
        switch (e.key) {
          case 'ArrowUp':
            handleScroll(-1);
            break;
          case 'ArrowDown':
            handleScroll(1);
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openModal]);

  const handleScroll = (direction) => {
    const modalContent = document.getElementsByClassName('modal-main');

    if (modalContent) {
      modalContent[0].scrollTop += direction * 20; // Adjust the scrolling speed as needed
      setScrollPosition(modalContent.scrollTop);
    }
  };
  
  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
    document.body.classList.add('workplace-modal-open');
  }
  const handleCloseModal = (e) => {
    e.preventDefault();
    setOpenModal(false);
    document.body.classList.remove('workplace-modal-open');
  }

  return (
    <>
      <div className="image-container">
        <img className="first-img" src={firstPic} alt="Mindful Hands" />
        <div className="overlay-container">
          <div className="overlay-main">
            <h1 className="main-title">{text}</h1>

            <p className="first-paragraph">
              JETSWEAT, the tested and trusted wellness partner for
              employers and health plans, brings about life-changing outcomes
              and boosts business performance across the board.
            </p>

            <button className="first-button" onClick={handleOpenModal}>Get in Touch</button>
          </div>
        </div>
      </div>

      <div className='two-columns'>
        {/* column left */}
        <div className='who-we-serve'>
          <h2 className="who-we-serve-header">Who We Serve</h2>
          <p className="who-we-serve-body">
            Workplace Wellness, a cutting-edge offering from JETSWEAT,
            designed to empower employers in fostering the well-being of their
            employees and ensuring business outcomes.
          </p>
        </div>

        {/* column right */}
        <div className="hr-column">
          <img src={hrImage} alt="HR Image" className="hr-image" />
          <div className="hr-text">
            <h2 className="hr-header"> HR & Employers</h2>
            <p className="hr-body">
              Human Resources (HR) and employers, work hand in hand to create
              a culture of well-being that empowers employees and drives
              business success.
            </p>
          </div>
        </div>
      </div>

      {/* Business Needs Section */}
      <div className="business-needs">
        <div>
          <h2 className="business-header">We Meet Business Needs</h2>
          <p2 className="business-body">
            Our top-of-the-line wellness resources cater to individual needs,
            lifestyles, and busy schedules, ensuring a perfect fit for every
            member of your workforce.
          </p2>
        </div>

        <div className="three-columns">
          <div className="business-column">
            <div className="business-icon-and-header">
              <img src={increaseRetentionImg} alt="Increase Retention Icon" />
              <div className="business-column-header">
                <h2>Increase Retention</h2>
              </div>
            </div>
            <p className="business-column-body">
              Captivate employee well-being and foster a deep sense of loyalty
              and connection.
            </p>
          </div>
          <div className="business-column">
            <div className="business-icon-and-header">
              <img src={improveProductivityImg} alt="Check on Board Icon" />
              <div className="business-column-header">
                <h2>Improve Productivity</h2>
              </div>
            </div>
            <p className="business-column-body">
              Nurture your employees' inner harmony, unveiling enhanced
              well-being that wards off absenteeism
            </p>
          </div>
          <div className="business-column">
            <div className="business-icon-and-header">
              <img src={businessSavingsImg} alt="Down Arrow Icon" />
              <div className="business-column-header">
                <h2>$3.27 saved / $1 spent</h2>
              </div>
            </div>
            <p className="business-column-body">
              Reduce medical costs through proactive nourishment of employees'
              health and foster a resilient workforce.
            </p>
          </div>
        </div>
      </div>
      {/* Business Needs Section Ends*/}

      {/* Empower Health Section */}
      <div className="empower-background">
        <h2 className="empower-header">
          Empower Health Outcomes while Driving Business Results.
        </h2>
        <p className="empower-main-p">
          As collective physical and mental health suffers, it’s all hands on deck to
          address the crisis.
        </p>
        <div className="three-columns-business">
          <div className="business-column">
            <div className="overlapping-percentage">
              <img
                src={grayEclipseImg}
                alt="85 percent"
                className="percent-first-img"
              />
              <img
                src={EightFive}
                alt="85 percent"
                className="percent-second-img"
              />
            </div>

            <div className="empower-text">
              <p className="empower-paragraph">
                85% of employees are likely to stay in a role if their wellbeing is taken care of
              </p>
            </div>
          </div>

          <div className="business-column">
            <div className="overlapping-percentage">
              <img
                src={grayEclipseImg}
                alt="83 percent"
                className="percent-first-img"
              />
              <img
                src={EightyThree}
                alt="83 percent"
                className="percent-second-img"
              />
            </div>
            <p className="empower-paragraph">
              83% of employees agree wellbeing support is equally important as compensation            </p>
          </div>

          <div className="business-column">
            <div className="overlapping-percentage">
              <img
                src={grayEclipseImg}
                alt="75 percent"
                className="percent-first-img"
              />
              <img
                src={SeventyFive}
                alt="75 percent"
                className="percent-second-img"
              />
            </div>
            <p className="empower-paragraph">
              75% of employees would leave if their company did not focus on their wellness
            </p>
          </div>
        </div>
      </div>
      {/* Empower Health Section Ends */}

      {/* Ignite Section */}
      <div className="ignite-container">
        <div className="ignite-text">
          <h2 className="ignite-header">
            Igniting Business Success Through a Healthy Workforce
          </h2>
          <p2 className="ignite-paragraph">
            True transformation happens when individuals experience their
            optimal selves.
          </p2>

          <div>
            <button className="second-button" onClick={handleOpenModal}>Get in Touch</button>
          </div>
        </div>

        <img src={igniteImg} alt="Hand holding plants" className="ignite-image" />
      </div>
      {/* Ignite Session Ends */}
      
      <Modal style={{overlay: {backgroundColor: 'rgba(0,0,0,0.75'}}} 
        isOpen={openModal} 
        className={`workplace-modal ${openModal ? 'open' : ''}`}
        shouldCloseOnOverlayClick={true}
        >
        
        <div className='modal-main'>
          <button 
            className="close-modal-button" 
            onClick={handleCloseModal}
          ><img src={closeButton}/> </button>
          <div className='modal-header'>
            <h3>Get In Contact</h3>
            <p>Ask about our flexible pricing options!</p>
          </div>
          <div id="hubspotFormModal"  className='hubspot-form'></div>
        </div>
        
      </Modal> 

      <Footer />
    </>
  );
}

export default WorkplaceWellness;
