import React from 'react'
const Day = (props) => {
  const { whiteLabelFontStyles } = props;

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'start', 
  };

  const textStyle = {
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '20px', 
  };

  const dotStyle = {
    borderRadius: '50%',
    border: '2px solid #c7c7c7',
    width: '8px',
    height: '8px',
    margin: '4px 0', 
  };

  const dayAndDate = (
    <>
      <div className="day" style={textStyle}>{props.day}</div>
      <div className="date" style={textStyle}>{props.date.getDate()}</div>
    </>
  );

  const completed = {
    border: `4px solid ${
      (whiteLabelFontStyles && whiteLabelFontStyles.color) || '#9ac8b9'
    }`,
    backgroundColor:
      (whiteLabelFontStyles && whiteLabelFontStyles.color) || '#9ac8b9',
  };

  const skipped = {
    border: '4px solid #7d92a5',
    backgroundColor: '#7d92a5',
  };

  const scheduled = {
    border: `1px solid  ${
      (whiteLabelFontStyles && whiteLabelFontStyles.color) || '#9ac8b9'
    }`,
  };

  const dateStyle = {
    textAlign: 'center',
    fontSize: '16px',
  };



  if (props.status === 'completed') {
    return (
      <div style={containerStyle}>
        {dayAndDate}
        <div style={{ ...dotStyle, ...completed }} />
      </div>
    );
  }
  if (props.status === 'skipped') {
    return (
      <div style={containerStyle}>
        {dayAndDate}
        <div style={{ ...dotStyle, ...skipped }} />
      </div>
    );
  }
  if (props.status === 'scheduled') {
    return (
      <div style={containerStyle}>
        {dayAndDate}
        <div style={{ ...dotStyle, ...scheduled }} />
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      {dayAndDate}
    </div>
  );
};

export default Day;
