import React, { Component, lazy, Suspense } from "react";
import { BeatLoader as Loader } from "react-spinners";
import Footer from "components/footer";
import Navbar from "./sections/navbar/navbar";
import "./app.css";

const Hero = lazy(() => import("./sections/hero/hero"));
const Why = lazy(() => import("./sections/why/why"));
const Explore = lazy(() => import("./sections/explore/explore"));
const People = lazy(() => import("./sections/people/people"));
const Social = lazy(() => import("./sections/social/social"));
const Press = lazy(() => import("./sections/press/press"));

export default class Home extends Component {
  render() {
    const { studios, setLogo } = this.props;
    return (
      <div>
        <Navbar setLogo={setLogo.bind(this)} />
        <Suspense fallback={<Loader />}>
          <Hero />
          <Why />
          <Explore studios={studios} />
          <People />
          <Social />
          <Press />
          <Footer />
        </Suspense>
      </div>
    );
  }
}
