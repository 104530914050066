import React from "react"
import { Link } from "react-router-dom"

const CardTopBar = (props) => {
  const cardTitle = {
    color: "#4b4b4b",
    fontSize: "26px",
    marginTop: "16px",
    marginLeft: "16px",
    marginBottom: "4px",
    maxWidth: "70%",
  }

  const cardButton = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "16px",
    marginTop: "20px",
    marginRight: "16px",
    backgroundColor: "#c7c7c7",
    padding: "1rem 2rem",
    borderRadius: "8px",
    height: "20px",
  }
  return (
    <div className="card-top-bar">
      <div style={cardTitle}>
        {props.cardTitle}{" "}
      </div>
      {props.buttonText && (
        <div className="card-top-bar-button" style={cardButton}>
          <Link
            className="card-top-bar-text"
            to={props.toPath}
            onClick={props.onClick}
          >
            {props.buttonText}
          </Link>
        </div>
      )}
    </div>
  )
}

export default CardTopBar
